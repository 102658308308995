'use client';

import styled from 'styled-components';

export const FlexRow = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  width: ${(props) => props.width};
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

export const FlexInlineRow = styled.div`
  position: relative;
  display: inline-flex;
`;

export const FlexColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 10;
  width: ${(props) => props.width};
  height: 100%;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  max-width: 1650px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 280px;
  }
`;

export const CollectionContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 10;
  height: 100%;
  width: 100%;
  max-width: 1600px;
  z-index: 10000;
`;
