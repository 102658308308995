'use client';

import styled, { keyframes } from 'styled-components';

const bgAnimation = keyframes` 
  0%{background-position:0% 0%}
  50%{background-position:100% 100%}
`;

export const BgLightBlueCircles = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  opacity: 0.3;
  background: radial-gradient(
    circle at bottom right,
    rgba(49, 157, 235) 0%,
    rgb(49, 157, 235) 13%,
    rgb(76, 166, 234) 13%,
    rgb(76, 166, 234) 23%,
    rgb(103, 176, 232) 23%,
    rgb(103, 176, 232) 33%,
    rgb(130, 185, 231) 33%,
    rgb(130, 185, 231) 46%,
    rgb(156, 194, 230) 46%,
    rgb(156, 194, 230) 48%,
    rgb(183, 203, 229) 48%,
    rgb(183, 203, 229) 63%,
    rgb(210, 213, 227) 63%,
    rgb(210, 213, 227) 83%,
    rgb(237, 222, 226) 83%,
    rgb(237, 222, 226) 100%
  );
  background-size: 200% 200%;
  animation: ${bgAnimation} 300s ease-out infinite;
  transform: translateZ(1px);
`;

export const BgDarkGreyCircles = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  background-image: radial-gradient(
    circle at center left,
    rgb(46, 46, 46) 0%,
    rgb(46, 46, 46) 6%,
    rgb(41, 41, 41) 6%,
    rgb(41, 41, 41) 27%,
    rgb(36, 36, 36) 27%,
    rgb(36, 36, 36) 42%,
    rgb(31, 31, 31) 42%,
    rgb(31, 31, 31) 63%,
    rgb(25, 25, 25) 63%,
    rgb(25, 25, 25) 64%,
    rgb(20, 20, 20) 64%,
    rgb(20, 20, 20) 71%,
    rgb(15, 15, 15) 71%,
    rgb(15, 15, 15) 100%
  );
  background-size: 200% 200%;
  animation: ${bgAnimation} 300s ease-out infinite;
  transform: translateZ(1px);
`;
