'use client';

import styled from 'styled-components';

export const H1 = styled.h1`
  font-family: var(--font-DMSerifDisplay);
  color: ${(props) => props.theme.colors.darkGrey};
  font-size: 2.3rem;
  font-weight: 600;
  letter-spacing: 0.15rem;
  @media screen and (min-width: 768px) {
    font-size: 3rem;
  }
`;

export const H2 = styled.h2`
  font-family: var(--font-DMSerifDisplay);
  font-weight: 600;
  letter-spacing: 0.04rem;
  color: ${(props) => props.theme.colors.darkGrey};
  font-size: 1.8rem;
  margin: 0 0 3px 0;
  @media screen and (min-width: 768px) {
    font-size: 2.5rem;
  }
`;

export const H3 = styled.h3`
  font-family: ${(props) => props.theme.fonts.sansSerif};
  color: ${(props) => props.theme.colors.darkGrey};
  font-size: 1.3rem;
  margin: 0 0 3px 0;
  font-weight: 400;
  @media screen and (min-width: 768px) {
    font-size: 1.6rem;
  }
`;

export const H4 = styled.h4`
  color: ${(props) => props.theme.colors.darkGrey};
  font-size: 1.2rem;
  line-height: 1.4rem;
  margin: 10px 0;

  @media only screen and (min-width: 768px) {
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
`;

export const H5 = styled.h5`
  color: ${(props) => props.theme.colors.darkGrey};
  font-family: ${(props) => props.theme.fonts.sanSerif};
  font-size: 1rem;
  line-height: 1.1rem;
  margin: 10px 0;
  text-transform: uppercase;
`;

export const P = styled.p`
  font-family: ${(props) => props.theme.fonts.sansSerif};
  color: ${(props) => props.theme.colors.darkGrey};
  font-size: 1.1rem;
  line-height: 1.6rem;
  font-weight: 300;
`;

export const Strong = styled.strong`
  font-family: ${(props) => props.theme.fonts.sansSerif};
  color: ${(props) => props.theme.colors.darkGrey};
  font-size: 1.1rem;
  line-height: 1.6rem;
  font-weight: 500;
  color: var(--red);
`;
